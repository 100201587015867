// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-js": () => import("/opt/build/repo/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-post-academy-js": () => import("/opt/build/repo/src/templates/postAcademy.js" /* webpackChunkName: "component---src-templates-post-academy-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bookshelf-js": () => import("/opt/build/repo/src/pages/bookshelf.js" /* webpackChunkName: "component---src-pages-bookshelf-js" */),
  "component---src-pages-dialogues-exploration-js": () => import("/opt/build/repo/src/pages/dialogues/exploration.js" /* webpackChunkName: "component---src-pages-dialogues-exploration-js" */),
  "component---src-pages-dialogues-index-js": () => import("/opt/build/repo/src/pages/dialogues/index.js" /* webpackChunkName: "component---src-pages-dialogues-index-js" */),
  "component---src-pages-dialogues-instrumentation-js": () => import("/opt/build/repo/src/pages/dialogues/instrumentation.js" /* webpackChunkName: "component---src-pages-dialogues-instrumentation-js" */),
  "component---src-pages-dialogues-plant-monitor-js": () => import("/opt/build/repo/src/pages/dialogues/plant-monitor.js" /* webpackChunkName: "component---src-pages-dialogues-plant-monitor-js" */),
  "component---src-pages-dialogues-reflection-js": () => import("/opt/build/repo/src/pages/dialogues/reflection.js" /* webpackChunkName: "component---src-pages-dialogues-reflection-js" */),
  "component---src-pages-fabacademy-js": () => import("/opt/build/repo/src/pages/fabacademy.js" /* webpackChunkName: "component---src-pages-fabacademy-js" */),
  "component---src-pages-feed-js": () => import("/opt/build/repo/src/pages/feed.js" /* webpackChunkName: "component---src-pages-feed-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reflections-js": () => import("/opt/build/repo/src/pages/reflections.js" /* webpackChunkName: "component---src-pages-reflections-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

